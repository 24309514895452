import React, { useState, useEffect } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "./User.module.css";
import { userProperties, getUserForm , typeArray } from "Util/Property/UserProperties";
import GeneralProperties from "Util/Property/GeneralProperties";
import LoadingOverlay from "react-loading-overlay";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
import { Form, Field } from "react-final-form";
import { notyDefault, notyTypes } from "components/Noty/NotyCustom";
import axios from "../../../axios/axios-default";
import CustomInput from "components/CustomInput/CustomInput.js";
import UrlProperties from "Util/Property/UrlProperties";
import { Switch, Typography } from "@material-ui/core";
import ListAltOutlinedIcon from "@material-ui/icons/PeopleAlt";
import MapIcon from "@material-ui/icons/Room";
import Map from "views/Maps/Map";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import generalProperties from "Util/Property/GeneralProperties";
import Dialog from "@material-ui/core/Dialog";
import ImageUpload from "components/UploadImage/ImageUpload";
import ImageView from "components/UploadImage/imageView";

import moment from "moment";
import { checkPermissoinListAvailable } from "Util/Permission/CheckPermission";
import PermissionProperties from "Util/Property/PermissionProperties";
import Spinner from "components/Spinner/Spinner.js";
import { set } from "dot-object";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import ImageEditor from "components/UploadImage/ImageEdit";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { de } from "date-fns/locale";
const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAW4tSeL4Q-C3Tls4PmYIk14PnydnTxMb0&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `650px` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};
      this.setState({
        position: null,
        lat: "",
        lng: "",
        bounds: null,
        onMarkerMounted: (ref) => {
          refs.marker = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          });
        },
        // onSearchBoxMounted: ref => {
        //   refs.searchBox = ref;
        // },
        onPositionChanged: () => {
          const position = refs.marker.getPosition();
          console.log(position.toString());
        },
        onDrag: (e) => {
          // this.setState({ lat: e.latLng.lat });
          // this.setState({ lng: e.latLng.lng });
          this.props.latitudeChange(e.latLng.lat());
          this.props.longitudeChange(e.latLng.lng());
          // console.log("latitude = ", e.latLng.lat());
          // console.log("longtitude = ", e.latLng.lng());
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={props.isEdit ? 20 : 12}
    zoom={props.zoom}
    defaultCenter={{ lat: 6.9271, lng: 79.8612 }}
    center={props.center}
  >
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Search places here"
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `400px`,
          height: `35px`,
          padding: `5px 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
          position: "absolute",
          left: "50%",
          marginLeft: "10px",
          marginTop: "12px",
        }}
      />
    </SearchBox>
    {props.isMarkerShown && (
      <>
        <Marker
          position={
            props.latV && props.lngV
              ? { lat: props.latV, lng: props.lngV }
              : { lat: 6.9271, lng: 79.8612 }
          }
          draggable={true}
          ref={props.onMarkerMounted}
          onDrag={props.onDrag}
        />
      </>
    )}
  </GoogleMap>
));

Geocode.setApiKey("AIzaSyAW4tSeL4Q-C3Tls4PmYIk14PnydnTxMb0");
Geocode.setLanguage("en");
Geocode.setRegion("sc");

const User = (props) => {
  const refs = {};
  const [userForm, setUserForm] = useState(getUserForm());
  const [userHeading, setUserHeading] = useState(userProperties.addUser);
  const [userSubHeading, setUserSubHeading] = useState(
    userProperties.plsAddNewUser
  );
  const [initialData, setInitialData] = useState({});
  const [saveImage, setSaveImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const [user, setUser] = useState(localStorage.getItem("user_id"));
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [userType, setUserType] = useState(localStorage.getItem("userType"));
  const [isEdit, setIsEdit] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isCustomerUser, setIsCustomerUser] = useState(false);
  const [previousUserName, setPreviousUserName] = useState("");
  const [isView, setIsView] = useState(false);
  const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
  const [isImageUploadOpen, setIsImageUploadOpen] = useState(false);
  const [defaultLocation, setDefaulltLoaction] = useState({
    lat: 6.90546,
    lng: 79.85359,
  });
  const [zoom, setZoom] = useState(12);
  const [locationChanged, setLocationChanged] = useState(false);
  const [baseImage, setBaseImage] = useState({});
  const [hasImage, sethasImage] = useState(false);
  const [permissions, setPermissions] = useState({});

  const [asyncDataState, setAsyncDataState] = useState({
    userIdList: false,
  });

  const VEHICLE_USER_TYPE = "VEHICLE";

  const [lat, setLat] = React.useState();
  const [lon, setLon] = React.useState();
  const latitudeChange = (lat) => {
    setLat(lat);
  };

  const longitudeChange = (lon) => {
    setLon(lon);
  };

  useEffect(() => {
    // console.log(
    //   "Calling geocode in useEffect",
    //   userForm.address.inputProps.value
    // );
    if (isEdit) {
      Geocode.fromAddress(userForm.address.inputProps.value).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;

          setLat(lat);
          setLon(lng);
          setDefaulltLoaction({ lat: lat, lng: lng });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [userForm]);

  useEffect(() => {
    initiateData();
  }, [props.id]);

  const initiateData = async () => {
    setUserTypes()
    setIsLoading(true);
    let userId = 0;

    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      if (props.isProfile) {
        setIsProfile(true);
      }
      userId = props.id;
      setUserHeading(userProperties.viewUser);
      setUserSubHeading(userProperties.manageUser);
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam["id"] != "undefined") {
        userId = queryParam["id"];
        setUserHeading(userProperties.viewUser);
        setUserSubHeading(userProperties.manageUser);
        if (queryParam["isEdit"] === "true") {
          setIsEdit(true);
          setUserHeading(userProperties.editUser);
        } else {
          setIsView(true);
        }
      }
    }
    const url = new URL(window.location.href);
    const customerUser = url.searchParams.get("isCustomerUser");
    const customerUserScreen = customerUser === "true";
    if (customerUserScreen) {
      setIsCustomerUser(true);
      // await setCustomersForUser();
      setUserHeading(userProperties.addNewCustomerUser);
      setUserSubHeading(userProperties.plsAddNewCustomerUser);
    } else {
      setIsCustomerUser(false);
      // await setCustomersForUser();
    }
    if (userId > 0) {
      // this is for view and edit user
      // await getUsersForUserByRole();
      await getUserData(userId);
      setUserId(userId);
    } else if (!customerUserScreen) {
      // this is loading for add new User
      await getRolesForUser();
      // getAllLeaveSchemes();
      // await getUsersForUserByRole();
      // fetchVehicleTypes(customerUserScreen);
    }


    setIsLoading(false);
  };

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addUser,
      PermissionProperties.editUser,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);


  // const setDataToNotificationForm = (key, options, isFirstTime, isMultiple) => {
  //   // let serviceRequestFormData = { ...serviceRequestForm };
  //   let formData = userForm[key];
  //   let inputProps = { ...formData.inputProps };
  //   inputProps.options = options;
  //   let defaultValue;

  //   if (isFirstTime && inputProps.initvalue !== "") {
  //     defaultValue = inputProps.initvalue;
  //   } else {
  //     defaultValue = options.length > 0 ? options[0].value : "";
  //   }
  //   if (isMultiple) {
  //     let arr = [];
  //     defaultValue = arr;
  //   }
  //   inputProps.value = defaultValue;
  //   if (isFirstTime) {
  //     inputProps.initvalue = defaultValue;
  //   }
  //   formData.inputProps = inputProps;
  //   userForm[key] = formData;
  //   //setServiceRequestForm(serviceRequestFormData);
  //   checkForStateUpdate();
  //   return defaultValue;
  // };

  // const checkForStateUpdate = (isFirstTime) => {
  //   let status = true;
  //   for (let key in asyncDataState) {
  //     if (asyncDataState[key] === false) {
  //       status = false;
  //       return false;
  //     }
  //   }
  //   if (status) {
  //     let formData = { ...userForm };
  //     setUserForm(formData);
  //   }
  // };

  const getAllUserRoles = async () => {
    setIsLoading(true);
    try {
      const result = await axios.get(UrlProperties.user.getAllRoles);
      if (result.status === 200) {
        let userRoleOptions = [];
        result.data.map((role) =>
          userRoleOptions.push({ value: role.id, displayValue: role.name })
        );
        let userFormData = { ...userForm };
        let roleData = userFormData["role"];
        let inputProps = { ...roleData.inputProps };
        inputProps.options = userRoleOptions;
        let defaultValue =
          userRoleOptions.length > 0 ? userRoleOptions[0].value : "";
        inputProps.value = defaultValue;
        inputProps.initvalue = defaultValue;
        roleData.inputProps = inputProps;
        userFormData["role"] = roleData;
        setUserForm(userFormData);
        setIsLoading(false);
      } else {
        notyDefault({
          type: notyTypes.ERROR,
          text: result.data
            ? result.data.message
            : userProperties.messages.error.userRoles,
        });
        setIsLoading(false);
      }
    } catch (error) {
      notyDefault({
        type: notyTypes.ERROR,
        text: userProperties.messages.error.userRoles,
      });
      setIsLoading(false);
    }
  };

  // const getAllLeaveSchemes = async () => {
  //   setIsLoading(true);
  //   try {
  //     const result = await axios.get(UrlProperties.user.getAllLeaveScheme);
  //     if (result.status === 200) {
  //       let leaveSchemeOptions = [];
  //       result.data.map((scheme) =>
  //         leaveSchemeOptions.push({
  //           value: scheme.id,
  //           displayValue:
  //             scheme.scheme_name +
  //             ` ( Annual: ${scheme.annual_count}, ` +
  //             `Casual: ${scheme.casual_count}, ` +
  //             `Medical: ${scheme.medical_count} )`,
  //         })
  //       );
  //       let userFormData = { ...userForm };
  //       let leaveSchemeData = userFormData["leave_scheme_id"];
  //       let inputProps = { ...leaveSchemeData.inputProps };
  //       inputProps.options = leaveSchemeOptions;
  //       let defaultValue =
  //         leaveSchemeOptions.length > 0 ? leaveSchemeOptions[0].value : "";
  //       inputProps.value = defaultValue;
  //       inputProps.initvalue = defaultValue;
  //       leaveSchemeData.inputProps = inputProps;
  //       userFormData["leave_scheme_id"] = leaveSchemeData;
  //       setUserForm(userFormData);
  //       setIsLoading(false);
  //     } else {
  //       notyDefault({
  //         type: notyTypes.ERROR,
  //         text: result.data
  //           ? result.data.message
  //           : userProperties.messages.error.leaveSchemes,
  //       });
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     notyDefault({
  //       type: notyTypes.ERROR,
  //       text: userProperties.messages.error.leaveSchemes,
  //     });
  //     setIsLoading(false);
  //   }
  // };

  const getUser = async (userId) => {
    setIsLoading(true);
    try {
      const result = await axios.get(UrlProperties.user.getUser + "/" + userId);
      if (result.status === 200) {
        let formData = { ...userForm };
        let initData = {};

        setUserHeading(result.data.first_name + " " + result.data.last_name);
        await getUserImage(userId);
        for (let key in formData) {
          let elementData = formData[key]; // get data for form key
          let inputProps = { ...elementData.inputProps }; //  copy input data
          if (key === "password") {
            elementData.isHide = true;
          }
          if (result.data[key]) {
            if (key === "role") {
              inputProps.value = result.data[key].id; // here filter returns an array
              initData[key] = result.data[key].id;
            } else {
              initData[key] = result.data[key];
              inputProps.value = result.data[key]; // here filter returns an array
            }
          } else {
            initData[key] = "";
            inputProps.value = "";
          }
          if (key === "userIdList") {
            if (result.data["immediateSupervisors"]) {
              if (typeof result.data["immediateSupervisors"] === "object") {
                inputProps.value = result.data["immediateSupervisors"];
                initData[key] = result.data["immediateSupervisors"];
              } else {
                inputProps.value = [];
                initData[key] = [];
              }
            }
          }
          if (key === "field_user_type") {
            if (result.data["is_field_user"]) {
              inputProps.value = result.data["is_field_user"];
              initData[key] = result.data["is_field_user"];
            }
          }

          if (key === "join_date") {
            inputProps.value =
              inputProps.value[0] +
              "/" +
              inputProps.value[1] +
              "/" +
              inputProps.value[2];
          }
          elementData.inputProps = { ...inputProps };
          formData[key] = { ...elementData };
        }

        if (
          typeof result.data["latitude"] !== "undefined" &&
          result.data["latitude"] !== null &&
          result.data["latitude"] !== 0
        ) {
          let defalutLoc = {
            defaultLat: result.data["latitude"],
            defaultLng: result.data["longitude"],
          };
          setDefaulltLoaction(defalutLoc);
        }
        setUserForm(formData);
        setInitialData(initData);
        setIsLoading(false);
      } else {
        notyDefault({
          type: notyTypes.ERROR,
          text: result.data
            ? result.data.message
            : userProperties.messages.error.getUser,
        });
        setIsLoading(false);
      }
    } catch (error) {
      notyDefault({
        type: notyTypes.ERROR,
        text: userProperties.messages.error.loadData,
      });
      setIsLoading(false);
    }
  };

  const getUserData = async (userId, isCustomerUserScreen) => {
    // fetchVehicleTypes(isCustomerUserScreen);
    await getAllUserRoles();
    // await getAllLeaveSchemes();
    userId && (await getUser(userId));
  };

  // const fetchVehicleTypes = async (isCustomerUserScreen) => {
  //   setIsLoading(true);
  //   if (!isCustomerUserScreen) {
  //     try {
  //       const result = await axios.get(
  //         UrlProperties.user.getActiveVehicleTypes
  //       );
  //       if (result.status === 200) {
  //         let VehicleTypeOptions = [];
  //         result.data.map((vehicleType) =>
  //           VehicleTypeOptions.push({
  //             value: vehicleType.id,
  //             displayValue: vehicleType.name,
  //           })
  //         );
  //         let userFormData = { ...userForm };
  //         let vehicleType = userFormData["vehicle_type_id"];
  //         let inputProps = { ...vehicleType.inputProps };
  //         inputProps.options = VehicleTypeOptions;
  //         vehicleType.inputProps = inputProps;
  //         userFormData["vehicle_type_id"] = vehicleType;
  //         setUserForm(userFormData);
  //         // setIsLoading(false);
  //       } else {
  //         notyDefault({
  //           type: notyTypes.ERROR,
  //           text: result.data
  //             ? result.data.message
  //             : userProperties.messages.error.userRoles,
  //         });
  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       notyDefault({
  //         type: notyTypes.ERROR,
  //         text: userProperties.messages.error.userRoles,
  //       });
  //       setIsLoading(false);
  //     }
  //   }
  // };

  // const setCustomersForUser = async () => {
  //   setIsLoading(true);
  //   await axios
  //     .get(UrlProperties.serviceRequest.getAllCustomers)
  //     .then((result) => {
  //       if (result.status === 200) {
  //         let customerOptions = [];
  //         let customerDOptions = [];
  //         result.data.map((customer) =>
  //           customerOptions.push({
  //             value: customer.id,
  //             displayValue: customer.name,
  //           })
  //         );
  //         let userFormData = { ...userForm };
  //         let customerData = userFormData["customer_id"];
  //         let inputProps = { ...customerData.inputProps };
  //         inputProps.options = customerOptions;
  //         let defaultValue =
  //           customerOptions.length > 0 ? customerOptions[0].value : "";
  //         inputProps.value = defaultValue;
  //         inputProps.initvalue = defaultValue;
  //         customerData.inputProps = inputProps;
  //         userFormData["customer_id"] = customerData;

  //         let dCustomerData = userFormData["customer_distributor_id"];
  //         let inputDProps = { ...dCustomerData.inputProps };
  //         let parentCustomers = [];
  //         result.data.forEach((customer) => {
  //           if (customer.customer_type != "CHILD") {
  //             parentCustomers.push({
  //               value: customer.id,
  //               displayValue: customer.name,
  //             });
  //           }
  //         });
  //         inputDProps.options = parentCustomers;
  //         // let defaultDValue =
  //         //   customerOptions.length > 0 ? customerOptions[0].value : '';
  //         //   inputDProps.value = defaultDValue;
  //         //   inputDProps.initvalue = defaultDValue;
  //         dCustomerData.inputProps = inputDProps;
  //         userFormData["customer_distributor_id"] = dCustomerData;
  //         setIsLoading(false);
  //         setUserForm(userFormData);
  //       } else {
  //         notyDefault({
  //           type: notyTypes.ERROR,
  //           text: result.data
  //             ? result.data.message
  //             : userProperties.messages.error.customers,
  //         });
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       notyDefault({
  //         type: notyTypes.ERROR,
  //         text: userProperties.messages.error.customers,
  //       });
  //       setIsLoading(false);
  //     });
  // };

  // const getUsersForUserByRole = async () => {
  //   setIsLoading(true);
  //   await axios
  //     .get(UrlProperties.users.getUsersByRole + "/" + userRole)
  //     .then((result) => {
  //       if (result.status === 200) {
  //         let userNameOptions = [];
  //         result.data.map((user) =>
  //           userNameOptions.push({
  //             value: user.id,
  //             displayValue: user.first_name,
  //           })
  //         );

  //         let userFormData = { ...userForm };
  //         let userData = userFormData["parent_user_id"];

  //         let inputProps = { ...userData.inputProps };

  //         inputProps.options = userNameOptions;
  //         let defaultValue = [];
  //         inputProps.value = defaultValue;
  //         inputProps.initvalue = defaultValue;
  //         userData.inputProps = inputProps;
  //         userFormData["parent_user_id"] = userData;
  //         setUserForm(userFormData);
  //       } else {
  //         notyDefault({
  //           type: notyTypes.ERROR,
  //           text: result.data
  //             ? result.data.message
  //             : userProperties.messages.error.loadData,
  //         });
  //       }
  //       setDefaultData();
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       notyDefault({
  //         type: notyTypes.ERROR,
  //         text: userProperties.messages.error.loadData,
  //       });
  //     });
  // };

  const setUserTypes = async () => {
    let userRole = localStorage.getItem("role");
    let userTypeOptions = [];
    let newUserTypeArray = []

    switch (parseInt(userRole)) {
      case 1:
      case 2:
        newUserTypeArray = typeArray;
        break;
      default:
        newUserTypeArray = typeArray.filter((type) => type.value == VEHICLE_USER_TYPE);
        break;
    }

    newUserTypeArray.map((type) =>
      userTypeOptions.push({ value: type.value, displayValue: type.displayValue })
    );

    let userFormData = { ...userForm };
    let typeData = userFormData["type"];
    let inputProps = { ...typeData.inputProps };
    inputProps.options = userTypeOptions;
    let defaultValue = userTypeOptions.length > 0 ? userTypeOptions[0].value : "";
    inputProps.value = defaultValue;
    inputProps.initvalue = defaultValue;
    typeData.inputProps = inputProps;
    userFormData["type"] = typeData;
    setUserForm(userFormData);
  };

  const getRolesForUser = async () => {
    setIsLoading(true);
    await axios
      .get(UrlProperties.user.getAllChildRoles + "/" + userRole)
      .then((result) => {
        if (result.status === 200) {
          let userRoleOptions = [];
          result.data.map((role) =>
            userRoleOptions.push({ value: role.id, displayValue: role.name })
          );

          let userFormData = { ...userForm };
          let roleData = userFormData["role"];
          let inputProps = { ...roleData.inputProps };
          inputProps.options = userRoleOptions;
          let defaultValue = userRoleOptions.length > 0 ? userRoleOptions[0].value : "";
          inputProps.value = defaultValue;
          inputProps.initvalue = defaultValue;
          roleData.inputProps = inputProps;
          userFormData["role"] = roleData;
          setUserForm(userFormData);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : userProperties.messages.error.userRoles,
          });
          setIsLoading(false);
        }
        setDefaultData();
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: userProperties.messages.error.userRoles,
        });
      });
  };


  const onChangeHandeler = (event, elId, isMap) => {
    let formData = { ...userForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    let value = "";
    // console.log("event" + event);
    // inputProps.value = event.target.value;
    if (elementData.elType == "date") {
      inputProps.value = event;
      value = event;
    } else if (elementData.elType == "checkbox") {
      inputProps.value = event.target.checked;
      value = event.target.checked;
    } else if (elementData.elType == "input" && elId == "address") {
      inputProps.value = isMap ? event : event.target.value;
      value = isMap ? event : event.target.value;
    } else {
      inputProps.value = event.target.value;
      value = event.target.value;
    }

    // if (elId === "type") {
    //   if (value == "USER") {
    //     formData["role"].inputProps.value = null;
    //   }
    // }
    // if (elId === "join_date") {
    //     // console.log("Dateeeeeeeeeeeeeeeeeeeeeee",_date)
    //     const tzDate = moment.tz(value.format("YYYY-MM-DD HH:mm:ss"), "Asia/Singapore")

    // }

    // if (elId === "join_date") {
    //     inputProps.value = event.toDate();
    //     console.log("Dateeeeee  event ",inputProps.value);
    //     console.log("Dateeeeeeeeeeeeeeeeeeeeeee",inputProps.value);
    //     value = event.toDate();
    //   } else {
    //     inputProps.value = event.target.value;
    //     value = event.target.value;
    //   }

    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    if (elId === "user_name") {
      setPreviousUserName(inputProps.value);
    }
    setUserForm(formData);
  };

  const dateFormat = (val) => {
    let dateVal = val;
    if (dateVal.length < 2) {
      dateVal = `0${val}`;
      return dateVal;
    } else {
      return val;
    }
  };
  const searchArea = async (element, key) => {
    if (key.length > 0) {
      setIsLoading(true);

      await axios
        .get(UrlProperties.user.searchUsersArea + "?search-text=" + key)
        .then((result) => {
          if (result.status === 200) {
            if (result.data.length > 0) {
              let areaOptions = [];
              result.data.map((area) =>
                areaOptions.push({
                  value: area,
                  displayValue: area,
                })
              );

              let formData = { ...userForm };
              let elementData = formData[element.id]; // get data for form key
              let inputProps = {
                ...elementData.inputProps,
                options: areaOptions,
              };
              elementData.inputProps = inputProps;
              formData[element.id] = elementData;

              setUserForm(formData);
            }
            setIsLoading(false);
          } else {
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : userProperties.messages.error.area,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: userProperties.messages.error.area,
          });
        });
      element.config.inputProps.tempValue = "";
    }
  };

  const onUserSubmit = async (values) => {
    let method = "";
    let url = "";
    let dataValues = { ...values };
    let data = {};

    for (let i in dataValues) {
      let val = dataValues[i];
      if (typeof val !== "undefined" && val !== "") {
        data[i] = val;
      }
    }
    let userFormData = { ...userForm };
    if (!isCustomerUser) {
      data["role"] = { id: userFormData["role"].inputProps.value };
    } else {
      data["role"] = { id: 1 };
      data["customer_id"] = userFormData["customer_id"].inputProps.value;
    }
    data["status"] = userFormData["status"].inputProps.value;
    data["type"] = userFormData["type"].inputProps.value;
    data["area"] = userFormData["area"].inputProps.value;
    data["latitude"] = defaultLocation.defaultLat;
    data["longitude"] = defaultLocation.defaultLng;
    // console.log("base64Image" + baseImage);
    data["join_date"] = userFormData["join_date"].inputProps.value;
    // delete data['join_date'];
    // data['join_date'] = (userFormData["join_date"].inputProps.value + 1);

    let newDate = new Date(data["join_date"]);

    let finalDay = dateFormat(newDate.getDate().toString());
    let finalMonth = dateFormat((newDate.getMonth() + 1).toString());
    let finalYear = newDate.getFullYear();

    data["join_date"] = `${finalYear}-${finalMonth}-${finalDay}`;

    // data['immediateSupervisors'] = userFormData['userIdList'].inputProps.value;

    // if (!dataValues.leave_scheme_id && !isCustomerUser) {
    //   data['leave_scheme_id'] =
    //     userFormData['leave_scheme_id']?.inputProps?.options[0]?.value;
    // }

    // if (!dataValues.department) {
    //   data['department'] =
    //     userFormData['department'].inputProps.options[0].value;
    // }
    data["is_field_user"] = userFormData["field_user_type"].inputProps.value;

    // if (baseImage !== undefined && baseImage.length > 0) {
    //   data['image'] = baseImage;
    // }
    // data["image"] = baseImage;
    if (userId > 0) {
      data["id"] = userId;
      method = "PUT";
      url = "updateUser/" + userId;
    } else {
      data["id"] = 0;
      method = "POST";
      url = "createUser";
    }

    setIsLoading(true);
    await axios({
      method: method,
      url: url,
      data: data,
    })
      .then((result) => {
        //this.props.history.goBack();
        setIsLoading(false);
        notyDefault({
          type: notyTypes.SUCCESS,
          text: userProperties.messages.success.saveData,
        });
        props.history.goBack();
      })
      .catch((error) => {
        setIsLoading(false);
        let errorMsg = JSON.parse(error.request.response);
        let errorDetail = errorMsg.details;
        notyDefault({
          type: notyTypes.ERROR,
          text: errorDetail ? errorDetail :userProperties.messages.error.saveData,
        });
      });
  };

  const setDefaultData = () => {
    let formData = { ...userForm };
    let defaultData = { ...userProperties.userForm };
    for (let key in defaultData) {
      let elementData = formData[key]; // get data for form key
      let inputProps = { ...elementData.inputProps }; //  copy input data
      inputProps.value = defaultData[key].inputProps.initvalue;
      elementData.inputProps = inputProps;
      if (key === "password") {
        elementData.isHide = false;
      }
      formData[key] = elementData;
    }
    setUserForm(formData);
  };

  const validateUsername = async (value) => {
    if (
      (isEdit && initialData.user_name === value) ||
      value === previousUserName
    ) {
      return undefined;
    }

    if (!value) {
      return GeneralProperties.emptyField;
    }

    let validation = "";
    await axios
      .get("isUserExists?userName=" + value)
      .then((result) => {
        if (result.data.isExist) {
          validation = userProperties.messages.error.userNameTaken;
        }
      })
      .catch((error) => {
        // notyDefault({type:notyTypes.ERROR, text:customerProperties.messages.error.deleteCustomer});
      });
    if (validation !== "") {
      return validation;
    }
  };

  const checkForEmpltyFields = (value, values) => {
    let val = 0;
  };

  const validateEmail = (value) => {
    if (!value) {
      return GeneralProperties.emptyField;
    }
    if (!userProperties.emailPatern.test(value)) {
      return GeneralProperties.validEmail;
    }
  };

  const mockValidation = (values) => {};

  const getValidationFunction = (validations) => {
    let functionName = "";
    if (!validations) {
      return mockValidation;
    } else {
      functionName = validations[0];
    }
    if (functionName === "checkForEmpltyFields") {
      return checkForEmpltyFields;
    } else if (functionName === "validateUsername") {
      return validateUsername;
    } else if (functionName === "validateEmail") {
      return validateEmail;
    } else {
      return mockValidation;
    }
  };

  const goBackHandler = () => {
    props.history.goBack();
  };

  let formElementArray = [];
  for (let key in userForm) {
    if (isCustomerUser) {
      if (
        key !== "userIdList" &&
        key !== "role" &&
        key !== "vehicle_type_id" &&
        key !== "ownership_type" &&
        key !== "department"
      ) {
        formElementArray.push({
          id: key,
          config: userForm[key],
        });
      }
    } else {
      if (key !== "customer_id") {
        formElementArray.push({
          id: key,
          config: userForm[key],
        });
      }
    }
  }

  const mapDialogOpenHandler = (id) => {
    setIsMapDialogOpen(true);
  };

  const mapDaialogCloseHandler = () => {
    setIsMapDialogOpen(false);
  };

  const mapConfrimHandler = (id) => {
    // updateUserImage(saveImage);
    Geocode.fromLatLng(parseFloat(lat), parseFloat(lon)).then(
      (response) => {
        const address = response.results[0].formatted_address;
        // setCustomLat(lat);
        // setCustomLng(lng);
        onChangeHandeler(String(address), "address", true);
      },
      (error) => {
        console.log(error);
      }
    );
    setIsMapDialogOpen(false);
  };

  const placeSelectHandler = (lat, lng) => {
    let location = { ...defaultLocation };
    location.defaultLat = lat;
    location.defaultLng = lng;
    setDefaulltLoaction(location);
    setLocationChanged(true);
  };

  const getUserImage = async (userId) => {
    // setIsLoading(true);
    try {
      const result = await axios.get(
        UrlProperties.user.getUserImage + "/" + userId
      );
      const userImage = {
        imageId: result.data.id,
        userId: result.data.user_id,
        image: result.data.image,
      };

      setBaseImage(userImage);
    } catch (error) {
      setIsLoading(false);
      console.log("Error occured while get image", error);
    }
  };

  // const updateUserImage = async (convertedImage) => {
  //   const userImageEx = JSON.parse(localStorage.getItem("userImage")) || null;
  //   console.log("User image exxxxxxxxxxxxxxxxxxxxxxxxx" + userImageEx);

  //   if (userImageEx != null && Object.keys(userImageEx).length != 0) {
  //     const userImageNew = {
  //       id: userImageEx.imageId,
  //       image: convertedImage,
  //     };
  //     try {
  //       setIsLoading(true);
  //       const result = await axios.patch(
  //         UrlProperties.user.updateUserImage + "/" + userImageEx.imageId,
  //         userImageNew
  //       );
  //       const userImageUpdated = {
  //         imageId: result.data.id,
  //         userId: result.data.user_id,
  //         image: result.data.image,
  //       };
  //       localStorage.setItem("userImage", JSON.stringify(userImageUpdated));
  //       setBaseImage(userImageUpdated);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.log("Error occured while updating the image", error);
  //     }
  //   } else {
  //     console.log("User image yyyyyyyyyyyyyyyyyyyyyyyyy" + userImageEx);
  //     const userId = parseInt(localStorage.getItem("userId")) || null;
  //     const userImageNew = {
  //       user_id: userId,
  //       image: convertedImage,
  //     };
  //     try {
  //       setIsLoading(true);
  //       const result = await axios.post(
  //         UrlProperties.user.createUserImage,
  //         userImageNew
  //       );
  //       const userImageUpdated = {
  //         imageId: result.data.id,
  //         userId: result.data.user_id,
  //         image: result.data.image,
  //       };
  //       localStorage.setItem("userImage", JSON.stringify(userImageUpdated));
  //       setBaseImage(userImageUpdated);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.log("Error occured while updating the image", error);
  //     }
  //   }
  // };

  const handleimageupload = (convertedImage, ImgTrue) => {
    console.log("------------->", convertedImage);
    setSaveImage(convertedImage);
    sethasImage(ImgTrue);
  };
  const onSearchBoxMounted = (ref) => {
    refs.searchBox = ref;
  };
  const onPlacesChanged = () => {
    const places = refs.searchBox.getPlaces();
    const location = places[0].geometry.location;
    const lat = location.lat();
    const lng = location.lng();
    console.log("Selected Location:", { lat, lng });
    setZoom(20);
    setDefaulltLoaction({ lat: lat, lng: lng });

    setLat(lat);
    setLon(lng);
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={isMapDialogOpen}
                onClose={mapDaialogCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div className={styles.map_container_height}>
                    {/* <Map
                      google={props.google}
                      center={{
                        lat: defaultLocation.defaultLat,
                        lng: defaultLocation.defaultLng,
                      }}
                      height="600px"
                      zoom={15}
                      placeHandler={placeSelectHandler}
                      marker={{ dragable: true }}
                      address={userForm.address.inputProps.value}
                    /> */}
                    <MyMapComponent
                      isMarkerShown={true}
                      latV={lat}
                      lngV={lon}
                      latitudeChange={latitudeChange}
                      longitudeChange={longitudeChange}
                      onSearchBoxMounted={onSearchBoxMounted}
                      onPlacesChanged={onPlacesChanged}
                      center={defaultLocation}
                      zoom={isEdit ? 20 : zoom}
                      isEdit={isEdit}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="sm"
                    onClick={mapDaialogCloseHandler}
                    color="primary"
                  >
                    {generalProperties.cancel}
                  </Button>
                  <Button
                    size="sm"
                    onClick={mapConfrimHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.confirm}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                //maxWidth="sm"
                maxWidth="xs"
                fullWidth={true}
                open={isImageUploadOpen}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent style={{ alignSelf: "center" }}>
                  <ImageEditor getConvertedImage={handleimageupload} />
                </DialogContent>
                <DialogActions>
                  <Button
                    size="sm"
                    onClick={() => {
                      setIsImageUploadOpen(false);
                    }}
                    color="primary"
                  >
                    {generalProperties.cancel}
                  </Button>
                  <Button
                    size="sm"
                    onClick={mapConfrimHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.confirm}
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="generic-form-container">
                <div className={"generic-form-header-wrapper"}>
                  <div className={styles["user-page-top"]}>
                    <div
                      className={"generic-page-title"}
                      style={{ paddingLeft: 20 }}
                    >
                      <span className={"generic-page-title-icon"}>
                        <ListAltOutlinedIcon />
                      </span>
                      <Typography
                        className={"generic-header-text"}
                        variant="h6"
                        style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                      >
                        {userHeading}
                      </Typography>
                      {/* <Typography variant="body1">{userSubHeading}</Typography> */}
                    </div>
                    <div style={{ paddingLeft: 20 }}>
                      {/* <ImageEditor /> */}
                      <ImageView
                        baseImage={baseImage.image}
                        // width = {'120px'}
                        // hight = {'120px'}
                        // borderRadius = {10}
                      />
                    </div>
                    <div
                      style={{
                        paddingTop: 15,
                        paddingLeft: 20,
                        alignSelf: "center",
                      }}
                    >
                      {isProfile ? (
                        // <ImageUpload getConvertedImage={handleimageupload} />
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => {
                            setIsImageUploadOpen(true);
                          }}
                        >
                          Upload Image
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <Form
                  onSubmit={onUserSubmit}
                  initialValues={initialData}
                  validate={(values, ss) => {
                    const errors = {};
                    if (!values.first_name) {
                      // setEror("first_name", true)
                      errors.first_name = GeneralProperties.emptyField;
                    }
                    if (!values.last_name) {
                      errors.last_name = GeneralProperties.emptyField;
                    }

                    if (!values.user_name) {
                      errors.user_name = GeneralProperties.emptyField;
                    }
                    if (!values.password && !isEdit) {
                      errors.password = GeneralProperties.emptyField;
                    }

                    // if (!values.address) {
                    //   errors.address = GeneralProperties.emptyField;
                    // }

                    let emailValidation = validateEmail(values.email);
                    if (emailValidation) {
                      errors.email = emailValidation;
                    }

                    // if (!values.employee_number) {
                    //   errors.employee_number = GeneralProperties.emptyField;
                    // }

                    // if (!values.designation) {
                    //   errors.designation = GeneralProperties.emptyField;
                    // }

                    if (!values.phone) {
                      errors.phone = GeneralProperties.emptyField;
                    } else {
                      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                      if (!values.phone.match(phoneno)) {
                        errors.phone = "Invalid Phone Number";
                      }
                    }

                    // if (!values.area) {
                    //   errors.area = GeneralProperties.emptyField;
                    // }

                    // if (!values.department) {
                    //   errors.department = GeneralProperties.emptyField;
                    // }

                    // if (!values.leave_scheme_id) {
                    //   errors.leave_scheme_id = GeneralProperties.emptyField;
                    // }

                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        <GridContainer>
                          {formElementArray.map((element) => {
                            if (
                              isCustomerUser &&
                              (element.id === "leave_scheme_id")
                            ) {
                              element.config.isHide = true;
                            } else {
                              element.config.isHide = false;
                            }
                            if (!isCustomerUser && element.id === "area") {
                              element.config.isHide = true;
                            } else {
                              element.config.isHide = false;
                            }

                            if(props.id>0){
                              if(element.id === "password"){
                                return
                              }
                            }

                            // if (
                            //   !isCustomerUser &&
                            //   element.id === "customer_distributor_id" &&
                            //   (userForm["type"].inputProps.value === "USER" ||
                            //     !userForm["type"].inputProps.value)
                            // ) {
                            //   element.config.isHide = true;
                            // } else {
                            //   element.config.isHide = false;
                            // }

                            if (!element.config.isHide) {
                              let mdVal = 12;
                              if (!element.config.isFullWidth) {
                                mdVal = 6;
                              }

                              return (
                                <GridItem
                                  key={element.id}
                                  xs={12}
                                  sm={12}
                                  md={mdVal}
                                >
                                  <Field
                                    name={element.id}
                                    validate={getValidationFunction(
                                      element.config.validations
                                    )}
                                  >
                                    {({ input, meta, options, value }) => (
                                      <div style={{ position: "relative" }}>
                                        {element.id === "address" ? (
                                          <GridContainer>
                                            <GridItem xs={10} sm={10} md={10}>
                                              <CustomInput
                                                labelText={element.config.label}
                                                id={element.id}
                                                inputProps={{
                                                  ...input,
                                                  ...element.config.inputProps,
                                                  readOnly: isView
                                                    ? true
                                                    : false,
                                                }}
                                                type={element.config.elType}
                                                formControlProps={
                                                  element.config
                                                    .formControlProps
                                                }
                                                changed={(event, value) => {
                                                  input.onChange(event);
                                                  onChangeHandeler(
                                                    event,
                                                    element.id
                                                  );
                                                }}
                                                labelProps={{
                                                  ...element.config.labelProps,
                                                  error:
                                                    meta.error && meta.touched,
                                                }}
                                              />
                                            </GridItem>
                                            <GridItem xs={2} sm={2} md={2}>
                                              <span
                                                className={styles.setting_block}
                                                title="Loacation on Map"
                                                onClick={mapDialogOpenHandler}
                                              >
                                                <MapIcon />
                                              </span>
                                            </GridItem>
                                          </GridContainer>
                                        ) : (
                                          <div
                                            style={{
                                              marginTop:
                                                element.config.elType ===
                                                  "checkbox" && "20px",
                                            }}
                                          >
                                            <CustomInput
                                              labelText={element.config.label}
                                              id={element.id}
                                              inputProps={{
                                                ...input,
                                                ...element.config.inputProps,
                                                readOnly: isView ? true : false,
                                              }}
                                              type={element.config.elType}
                                              formControlProps={
                                                element.config.formControlProps
                                              }
                                              changed={(event, value) => {
                                                input.onChange(event);
                                                onChangeHandeler(
                                                  event,
                                                  element.id
                                                );
                                              }}
                                              freeSolo={element.config.freeSolo}
                                              onChangeTextfield={
                                                element.id === "area"
                                                  ? (e) => {
                                                      searchArea(
                                                        element,
                                                        e.target.value
                                                      );
                                                      onChangeHandeler(
                                                        e,
                                                        element.id
                                                      );
                                                    }
                                                  : null
                                              }
                                              multiple={element.config.multiple}
                                              labelProps={{
                                                ...element.config.labelProps,
                                                error:
                                                  meta.error && meta.touched,
                                              }}
                                            />
                                          </div>
                                        )}
                                        {meta.error && meta.touched && (
                                          <span className={styles.formError}>
                                            {meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </GridItem>
                              );
                            }
                          })}
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        {!isView &&
                        (permissions[PermissionProperties.addUser] ||
                          permissions[PermissionProperties.editUser]) ? (
                          <Button
                            type="submit"
                            // disabled={
                            //   (submitting || !valid || pristine) &&
                            //   // !locationChanged
                            //   // && !hasImage
                            // }
                            color="primary"
                            autoFocus
                          >
                            {GeneralProperties.save}
                          </Button>
                        ) : (
                          ""
                        )}
                      </CardFooter>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default User;
