/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Typography } from "@material-ui/core";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "./ServiceRequests.module.css";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import generalProperties from "Util/Property/GeneralProperties";
import {
  serviceRequestProperties,
  getExportServiceRequestForm,
} from "Util/Property/ServiceRequestProperties";
import { notyDefault, notyTypes } from "components/Noty/NotyCustom";
import LoadingOverlay from "react-loading-overlay";
import CustomInput from "components/CustomInput/CustomInput";
import { getCustomerByName } from "services/Customer/CustomerService";
import { handleErrorMessage } from "services/CommonService";
import UrlProperties from "Util/Property/UrlProperties";
import { isCustomerUser } from "../../Util/Util";
import { Form, Field } from "react-final-form";
import { getServiceRequestCategoryCall } from "services/ServiceRequest/serviceRequestCategoryServices";
import urlProperties from "Util/Property/UrlProperties";


const ServiceRequestsExport = (props) => {
  const {
    exportServiceRequestForm,
    setexportServiceRequestForm,
    isExportDialogOpen,
    setIsExportDialogOpen,
    setDataToExportServiceRequestForm,
    notyfyExportStatus
  } = props;
  const [initialState, setInitialState] = useState({
    customer_id: "",
    request_type: "",
    startDate: null,
    endDate: null,
  });
  const [isExportDialogLoading, setIsExportDialogLoading] = useState(false);

  let formElementArray = [];
  for (let key in exportServiceRequestForm) {
    formElementArray.push({
      id: key,
      config: exportServiceRequestForm[key],
    });
  }

  useEffect(() => {
    getRequestTypeCategories();
  }, []);

  const exportDialogCloseHandler = () => {
    setIsExportDialogOpen(false);
    clearForm();
  };

  const onExportHandler = () => {
    let exportData={
      isFieldUser: false,
      user_role_id: localStorage.getItem("role"),
      startDate: exportServiceRequestForm.startDate.inputProps.value,
      endDate: exportServiceRequestForm.endDate.inputProps.value,
    }

    let customerValue = exportServiceRequestForm.customer_id.inputProps.value;
    if(customerValue){
      exportData.customerName=exportServiceRequestForm.customer_id.inputProps.options
      .find((option)=>option.value===customerValue).mainValue
    }

    let requestTypeValue = exportServiceRequestForm.request_type.inputProps.value;
    if(requestTypeValue && requestTypeValue!=="ALL"){
      exportData.requestTypeName=exportServiceRequestForm.request_type.inputProps.options
      .find((option)=>option.value===requestTypeValue).displayValue
    }

    let statusValue = exportServiceRequestForm.status.inputProps.value;
    if(statusValue==="COMPLETE"){
      exportData.status_filter="Closed"
    }else if(statusValue==="INCOMPLETE"){
      exportData.status_exclude_filter="Closed"
    }

    let url = urlProperties.baseUrl + urlProperties.serviceRequest.filterServiceRequestForExport + "?";
        url += "&sortColumn=" + "id";
        url += "&sortOrder=" + "&search="

        new Promise((resolve, reject) => {
          fetch(url, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(exportData),
          })
            .then((response) => {
              if (response.ok) {
                return response.blob(); 
              } else {
                return response.json(); 
              }
            })
            .then((blob) => {
              if (blob.size === 0) {
                notyDefault({
                  type: notyTypes.ERROR,
                  text: "No records found matching filter criteria",
                });
              }else{
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                const formattedDateTime = getFormattedDateTime();
                const fileName = `Service Requests SPAMED (${formattedDateTime}).xlsx`;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                resolve({
                  data: blob,
                });
                notyDefault({
                  type: notyTypes.SUCCESS,
                  text: "Report generated successfully",
                });
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
    clearForm();
    exportDialogCloseHandler();
    notyfyExportStatus("Report is being generated. Please wait !")
  };

  function getFormattedDateTime() {
    const now = new Date();
    
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}.${month}.${day}- ${hours}.${minutes}`;
}

  const getRequestTypeCategories = () => {
    setIsExportDialogLoading(true);
    getServiceRequestCategoryCall()
      .then((response) => {
        if (!response.success) {
          handleErrorMessage(
            response.data,
            "Cannot load Service request types"
          );
        } else {
          let requestTypeOptions = [];
          response.data.map((requestCategory) =>
            requestTypeOptions.push({
              value: requestCategory.id,
              displayValue: requestCategory.name,
            })
          );
          requestTypeOptions.sort((a, b) =>
            a.displayValue.localeCompare(b.displayValue)
          );
          requestTypeOptions.unshift({
            value: "ALL",
            displayValue: "All",
          })
          let defaultValue = setDataToExportServiceRequestForm(
            "request_type",
            requestTypeOptions,
            true
          );
        }
        setIsExportDialogLoading(false);
      })
      .catch((error) => {
        setIsExportDialogLoading(false);
        handleErrorMessage(error, "Cannot load Service request types");
      });
  };

  const searchCustomer = (element) => {
    const value = element.config.inputProps.tempValue;
    if (value.length > 0) {
      setIsExportDialogLoading(true);
      clearForm();
      getCustomerByName(value)
        .then((result) => {
          if (result.status === 200) {
            if (result.data.length > 0) {
              let customerOptions = [];
              result.data.map((customer) =>
                customerOptions.push({
                  value: customer.id,
                  displayValue: customer.name + " " + `(${customer.address})`,
                  mainValue: customer.name,
                })
              );

              let defaultVal = setDataToExportServiceRequestForm(
                "customer_id",
                customerOptions,
                true,
                null
              );
            }
            setIsExportDialogLoading(false);
          } else {
            clearForm();
            setIsExportDialogLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : serviceRequestProperties.messages.error.loadCustomers,
            });
          }
        })
        .catch((error) => {
          clearForm();
          setIsExportDialogLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: serviceRequestProperties.messages.error.loadCustomers,
          });
        });
      element.config.inputProps.tempValue = "";
    }
  };

  const onChangeExportFieldHandeler = async (event, elId, meta) => {
    let value;
    let formData = { ...exportServiceRequestForm };
    let elementData = formData[elId];
    if (elementData.elType == "date") {
      value = event.toDate();
    } else {
      value = event.target.value;
    }
    let inputProps = { ...elementData.inputProps };
    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setexportServiceRequestForm(formData);
  };

  const clearForm = () => {
    setDataToExportServiceRequestForm("customer_id", [], false);
    setDataToExportServiceRequestForm("startDate");
    setDataToExportServiceRequestForm("endDate");
  };

  const onServiceRequestExportFormSubmit = () => {};

  const preventFormSubmitFromEnter = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const exportForm = () => {
    return (
      <Form
        onSubmit={onServiceRequestExportFormSubmit}
        initialValues={initialState}
        validate={(values, ss) => {
          const errors = {};
          if (exportServiceRequestForm.customer_id.inputProps.value === "") {
            errors.customer_id = generalProperties.emptyField;
          }
          return errors;
        }}
        render={({
          handleSubmit,
          reset,
          submitting,
          pristine,
          valid,
          form,
        }) => (
          <form onSubmit={handleSubmit} onKeyDown={preventFormSubmitFromEnter}>
            <CardBody>
              <GridContainer>
                {formElementArray.map((element, formElementindex) => {
                  if (!element.config.isHide) {
                    let mdVal = element.config.size;
                    return (
                      <React.Fragment key={element.id + "_frag"}>
                        <GridItem key={element.id} xs={12} sm={12} md={mdVal}>
                          {fieldBuilder(element)}
                        </GridItem>
                      </React.Fragment>
                    );
                  }
                })}
              </GridContainer>
            </CardBody>
          </form>
        )}
      />
    );
  };

  function fieldBuilder(element) {
    return (
      <Field name={element.id}>
        {({ input, meta, options, value }) => (
          <div style={{ position: "relative" }}>
            <CustomInput
              labelText={element.config.label}
              id={element.id}
              inputProps={{
                ...input,
                ...element.config.inputProps,
                readOnly:
                  false || element.config.readOnly || element.config.doDisable
                    ? true
                    : false,
              }}
              type={element.config.elType}
              formControlProps={element.config.formControlProps}
              adornmentText={element.config.adornmentText}
              adornmentPosition={element.config.adornmentPosition}
              changed={(event, value) => {
                input.onChange(event);
                onChangeExportFieldHandeler(event, element.id, meta);
              }}
              onSelectInputChange={(event) => {
                element.config.inputProps.tempValue = event.target.value;
                if (event && (event.key === "Enter" || event.keyCode === 13)) {
                  if (element.id === "customer_id") {
                    searchCustomer(element);
                  }
                }
              }}
              onAdornmentClick={
                element.id === "customer_id"
                  ? () => {
                      searchCustomer(element);
                    }
                  : undefined
              }
              multiple={element.config.multiple}
              labelProps={{
                ...element.config.labelProps,
                error: meta.error && meta.touched,
              }}
            />
            {meta.error && meta.touched && (
              <span className={styles.formError}>{meta.error}</span>
            )}
          </div>
        )}
      </Field>
    );
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={isExportDialogOpen}
      onClose={exportDialogCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Card>
          <div className={"generic-form-header-wrapper"}>
            <div className={"generic-page-title"}>
              <span className={"generic-page-title-icon"}></span>
              <Typography className={"generic-header-text"} variant="h6">
                Export Service Requests
              </Typography>
              <Typography variant="body1">
                Export Service Requests into an Excel sheet
              </Typography>
            </div>
          </div>
          <CardBody>
            <LoadingOverlay
              active={isExportDialogLoading}
              spinner
              text="Loading ..."
            >
              {exportForm()}
            </LoadingOverlay>
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button size="sm" onClick={exportDialogCloseHandler} color="primary">
          {generalProperties.cancel}
        </Button>
        <Button size="sm" onClick={onExportHandler} color="primary" autoFocus>
          {generalProperties.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceRequestsExport;
